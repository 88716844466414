import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  padding-top: 40px;
  padding-bottom: 40px;
  min-height: 965px;

  @media ${device.tablet} {
    padding-top: 72px;
    padding-bottom: 96px;
    min-height: 1044px;
  }
  @media ${device.desktopLG} {
    min-height: 1047px;
  }
  @media ${device.desktopXL} {
    min-height: 1223px;
  }
`

export const Card = styled.div`
  border-bottom: 1px solid ${grayscale[200]};
  margin-bottom: 24px;

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;

    @media ${device.tablet} {
      margin-right: 0;
      border-bottom: 1px solid ${grayscale[200]};
      margin-bottom: 24px;
    }
  }

  @media ${device.tablet} {
    width: 216px;
    min-height: 294px;
    border-radius: 8px;
    border: 1px solid ${grayscale[200]};
    padding: 30px 26px;
    margin-right: 24px;
  }
  @media ${device.desktopLG} {
    width: 295.33px;
    min-height: 223px;
  }
  @media ${device.desktopXL} {
    width: 360px;
    min-height: 246px;
    padding-left: 24px;
    padding-right: 24px;
  }

  p {
    margin-bottom: 24px;
  }
`

export const VideoChamadas = styled.div`
  margin-top: 14px;

  @media ${device.tablet} {
    margin-top: 24px;
  }

  button {
    margin-top: 40px;
    height: 48px;
  }

  img {
    border-radius: 16px;
  }
`

export const PlayVideo = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 20px;

  @media ${device.tablet} {
    top: 30px;
  }
`
