import React from 'react'
import OpenVideo from 'src/components/OpenVideo'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'

import StarIcon from '@interco/icons/build-v4/orangeds/MD/star'
import StarUsers from '@interco/icons/build-v4/orangeds/MD/users'
import Pending from '@interco/icons/build-v4/orangeds/MD/pending'

import ImageWebp from 'src/components/ImageWebp'
import PlayVideoOrange from '../../../../assets/images/shared/play-video-orange.png'

import { Section, Card, VideoChamadas, PlayVideo } from './styles'
const ImageAtendimentoEmLibras = 'https://central-imagens.bancointer.com.br/images-without-small-versions/libras/image.webp'

type VantagensProps = {
  icon: React.ReactNode;
  title: string;
  description: string;
};

type ModalProps = {
  setIsOpen: Function;
  setDataLayer: Function;
}

const VANTAGENS_LIST = [
  {
    icon: <StarIcon color='#161616' width={26} height={26} />,
    title: 'Atendimento completo',
    description:
      'Por aqui, você pode tirar dúvidas, enviar elogios e sugestões, pedir o cancelamento de produtos ou serviços e também consegue registrar reclamações.',
  },
  {
    icon: <StarUsers color='#161616' width={26} height={26} />,
    title: 'Gratuito e inclusivo',
    description:
      'O atendimento por videochamada é gratuito e conta com um interprete de Libras.',
  },
  {
    icon: <Pending color='#161616' width={26} height={26} />,
    title: 'Atendimento todos os dias',
    description:
      'O canal de atendimento em Libras funciona todos os dias, a partir das 8h até às 22h. Ah! E não precisa de agendamento prévio.',
  },
]

const Vantagens = ({ icon, title, description }: VantagensProps) => (
  <Card>
    <div className='d-flex d-md-block align-items-center mb-3'>
      <div className='mr-3  mb-md-3'>{icon}</div>
      <h3 className='fs-16 lh-20 fs-xl-20 lh-xl-25 mb-0 text-grayscale--500 fw-600'>{title}</h3>
    </div>
    <p className='fs-14 lh-17 fs-xl-16 lh-xl-20 text-grayscale--400'>{description}</p>
  </Card>
)

export const CanalAtendimento = ({ setIsOpen, setDataLayer }: ModalProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const urlVideo = 'https://www.youtube.com/embed/ocvGk5Mx5qY?&autoplay=1'
  const width = useWidth()

  const dataLayers: IDataLayerParams = {
    section: 'dobra_02',
    section_name: 'Canal de atendimento em libras com tradução em tempo real',
    element_action: 'click button',
    element_name: 'Acessar videochamada em Libras',
  }

  return (
    <Section className='bg-grayscale--100'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-9 text-md-center mx-auto'>
            <h2 className='font-sora fw-600 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 mb-3 text-grayscale--500'>
              Canal de atendimento em libras com tradução em tempo real
            </h2>
          </div>
          <div className='col-12 col-lg-9 text-md-center mx-auto'>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--400 mb-4 pb-2 pb-lg-4 px-md-4 px-lg-5'>
              O atendimento é feito por videochamada, através da plataforma ICOM. Nele, você conta com uma pessoa
              intérprete de Libras para traduzir a conversa do atendimento em tempo real.
            </p>
          </div>
          <div className='col-12 d-md-flex justify-content-md-center'>
            {VANTAGENS_LIST.map((item: VantagensProps, index: number) => (
              <Vantagens key={index} {...item} />
            ))}
          </div>
          <VideoChamadas className='col-12'>
            <OpenVideo
              link={urlVideo}
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_02',
                  section_name: 'Canal de atendimento em libras com tradução em tempo real',
                  element_action: 'click video',
                  element_name: 'Assista ao vídeo',
                  redirect_url: urlVideo,
                })
              }}
            >
              <div className='d-flex justify-content-center cursor-pointer position-relative'>
                <ImageWebp
                  arrayNumbers={[ 324, 696, 934, 934 ]}
                  pathSrc={ImageAtendimentoEmLibras}
                  altDescription='Atendimento SAC em Libras'
                />
                <PlayVideo>
                  <img
                    src={PlayVideoOrange}
                    alt='Assistir ao vídeo'
                    width={ width < WIDTH_MD ? 50 : 85 }
                    height={ width < WIDTH_MD ? 75.88 : 129}
                  />
                </PlayVideo>
              </div>
            </OpenVideo>
            <div className='d-flex justify-content-center'>
              <button
                className='btn bg-orange--extra text-white fs-14 text-none'
                onClick={() => {
                  setIsOpen(true)
                  setDataLayer(dataLayers)
                  sendDatalayerEvent(dataLayers)
                }}
              >
                Acessar videochamada em Libras
              </button>
            </div>
          </VideoChamadas>
        </div>
      </div>
    </Section>
  )
}
