import React, { useState } from 'react'
import { Modal } from 'src/components/Modal'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useDomReady from 'src/hooks/window/useDomReady'
import Layout from 'src/layouts/BaseLayout'
import { ModalLibras } from './sections/ModalLibras/_index'

import { CanalAtendimento } from './sections/CanalAtendimento/_index'
import { Hero } from './sections/Hero/_index'

import pageContext from './pageContext.json'

const AtendimentoEmLibras = () => {
  const domReady = useDomReady()
  const [ isOpen, setIsOpen ] = useState(false)

  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_01',
    section_name: 'Atendimento em Libras',
    element_action: 'click button',
    element_name: 'Acessar videochamada em Libras',
  })

  const openModal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <ModalLibras
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='Videochamada em Libras'
        description='A videochamada é um método de atendimento exclusivo para pessoas com deficiência auditiva e de fala. Confirme sua condição abaixo.'
        oneOption='Sou uma pessoa surda ou com deficiência auditiva'
        twoOption='Não sou uma pessoa surda ou com deficiência auditiva'
        dataLayer={dataLayer}
        setDataLayer={setDataLayer}
      />
    </Modal>
  )
    return (
      <Layout pageContext={pageContext}>
        {openModal}
        <Hero setIsOpen={setIsOpen} dataLayer={dataLayer} />
        <CanalAtendimento setIsOpen={setIsOpen} setDataLayer={setDataLayer} />
      </Layout>
    )
}

export default AtendimentoEmLibras
