import { breakpoints, device } from 'src/styles/breakpoints';
import { grayscale, orange, white } from 'src/styles/colors';
import styled, { css, keyframes } from 'styled-components';

type ContainerProps = {
  isModal: boolean;
}

const animatedModal = keyframes`
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
`

export const Container = styled.div<ContainerProps>`
  top: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  transition: 0.5s ease-in-out;
  animation: ${animatedModal} 0.5s ease-in-out forwards;
  background-color: ${white};
  overflow-y: auto;
  display: block;
  height: 100%;

  ${(props: ContainerProps) => props.isModal && (
    css`
      min-height: 100vh;
      padding: 30px 24px;
      width: 100%;
      height: 100vh;
      display: flex;
      align-items: start;
      justify-content: start;

      @media (min-width: ${breakpoints.md}) {
        max-width: 640px;
        padding: 69px 80px;
      }
    `
  )}

  .open-modal {
    width: 100%;
    max-width: 342px;
  }
`

export const CloseButton = styled.div`
  position: absolute;
  background: none;
  border: none;
  top:30px;
  right: 30px;
  cursor: pointer;

  &:focus{
    outline: none;
  }
`

export const Image = styled.img`
  max-width: 187px;
  max-height: 187px;
`

export const LinksList = styled.div`
  
   span {
    cursor: pointer;
    border: 1px solid ${grayscale[200]};
    border-radius: 16px;
    background: ${white};
    font-family: 'Sora'; 
    font-weight: 600;
    color: ${grayscale['500']};
    margin-bottom: 24px;

    p {
      max-width: 310px;

      @media ${device.tablet} {
        max-width: initial;
      }
    }
  }
`

export const CentralDeAjuda = styled.div`
  background: ${grayscale[100]};
  min-height: 85px;
  padding: 16px;
  border-radius: 8px;
  margin-top: 24px;
  
  a {
    color: ${orange.extra};
    font-weight: 600;
    margin-bottom: 5px;
  }
`

export const LinkButton = styled.a`
  max-width: 100%;
  height: 48px;
`
