import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'
import styled from 'styled-components'

import BannerMobile from '../../assets/images/hero-banner-mobile.png'
import BannerTablet from '../../assets/images/hero-banner-tablet.png'
import BannerDesktopLG from '../../assets/images/hero-banner-desktop-lg.png'
import BannerDesktopXL from '../../assets/images/hero-banner-desktop-xl.png'

export const Section = styled.section`
  background: url(${BannerMobile}) ${orange.extra} no-repeat top center;
  min-height: 617px;
  padding-bottom: 40px;
  background-size: contain;

  @media ${device.tablet} {
    display: flex;
    align-items: center;
    background: url(${BannerTablet}) ${orange.extra} no-repeat center right;
    min-height: 544px;
    padding-bottom: 0;
  }
  @media ${device.desktopLG} {
    background: url(${BannerDesktopLG}) ${orange.extra} no-repeat center right;
  }
  @media ${device.desktopXL} {
    background: url(${BannerDesktopXL}) ${orange.extra} no-repeat center right;
    min-height: 600px;
  }

  button {
    max-width: 100%;
    height: 48px;
  }
`
