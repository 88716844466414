import { device } from 'src/styles/breakpoints'
import { grayscale, red, white } from 'src/styles/colors'
import { orange } from 'src/styles/newColors'
import styled from 'styled-components'

export const Card = styled.div`
  width: 100%;
  position: relative;

  input {
    height: 48px;
    width: 100%;
    background: ${grayscale[100]};
    border: none;
    border-radius: 8px;
    padding: 0 16px;
    color: ${grayscale[500]};
    font-size: 14px;

    &::placeholder {
      color: ${grayscale[400]};
    }
  }

  .form-label-check::before {
    top: 20px;

    @media ${device.tablet} {
      top: 13px;
    }
  }

  .form-input-check:checked ~ label::after {
    top: 23px;

    @media ${device.tablet} {
      top: 16px;
    }
  }

  .error {
    label, p {
      color: ${red[500]};
    }
    input {
      background: #FEF0ED;
      
      ::placeholder {
        color: ${red[500]};
      }
    }
  }
`

export const Button = styled.button`
  background: ${orange.extra};
  height: 48px;
  width: 100%;
  border-radius: 8px;
  color: ${white};
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  border: none;

  &:focus {
  outline: none;
  }

  &:disabled{
    background: ${grayscale[200]};
    color: ${grayscale[300]};
  }
`
