import React, { useState } from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import AccessFormLibras from '../Form/_index'

import { CentralDeAjuda, CloseButton, Container, LinkButton, LinksList } from './style'

type ModalLibrasProps = {
  isModal: boolean;
  setIsModal: Function;
  closeFirstModal?: (state: boolean) => void;
  title?: string;
  description?: string;
  oneOption: string;
  twoOption?: string;
  dataLayer: IDataLayerParams;
  setDataLayer: Function;
}

export const ModalLibras = ({
  isModal,
  setIsModal,
  title,
  description,
  oneOption,
  twoOption,
  dataLayer,
  setDataLayer,
  closeFirstModal,
}: ModalLibrasProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ notDisabled, setNotDisabled ] = useState(false)
  const [ openForm, setOpenForm ] = useState(false)

  return (
    <Container isModal={isModal}>
      <CloseButton onClick={() => {
        closeFirstModal && closeFirstModal(false)
        setIsModal(false)
      }}
      >
        <OrangeIcon icon='exit' size='MD' color='#ff7a00' />
      </CloseButton>
      <div className={`mt-5 ${notDisabled && 'd-none' }`}>
        {title &&
          <h3 className='font-sora fs-32 lh-40 text-grayscale--500 fw-600 mb-4' dangerouslySetInnerHTML={{ __html: title }} />
        }
        {
          description &&
            <p className='fs-16 lh-20 text-grayscale--400 fw-400 mb-4' dangerouslySetInnerHTML={{ __html: description }} />
        }
        {
          oneOption &&
            <LinksList>
              <span
                className='w-100 p-3 d-flex align-items-center justify-content-between'
                title={oneOption}
                onClick={() => {
                  setOpenForm(!openForm)
                  setDataLayer(dataLayer)
                  sendDatalayerEvent({
                    section: dataLayer.section,
                    section_name: dataLayer.section_name,
                    element_action: 'click button',
                    element_name: oneOption,
                  })
                }}
              >
                <p className='text-left fs-14 lh-17 text-grayscale--400 fw-600 mb-0' dangerouslySetInnerHTML={{ __html: oneOption }} />
                <OrangeIcon size='MD' color='#FF7A00' icon='chevron-right' />
              </span>
              {
                openForm &&
                  <AccessFormLibras />
              }
            </LinksList>
        }
        {
          twoOption &&
            <LinksList>
              <span
                className='p-3 d-flex align-items-center justify-content-between'
                title={twoOption}
                onClick={() => {
                  setNotDisabled(true)
                  sendDatalayerEvent({
                    section: dataLayer.section,
                    section_name: dataLayer.section_name,
                    element_action: 'click button',
                    element_name: twoOption,
                  })
                }}
              >
                <p className='text-left fs-14 lh-17 text-grayscale--400 fw-600 mb-0' dangerouslySetInnerHTML={{ __html: twoOption }} />
                <OrangeIcon size='MD' color='#FF7A00' icon='chevron-right' />
              </span>
            </LinksList>
        }
        <CentralDeAjuda>
          <div className='d-flex'>
            <div className='mr-3'>
              <OrangeIcon size='SM' color='#B6B7BB' icon='info' />
            </div>
            <div>
              <span className='fs-12 lh-15 text-grayscale--500 fw-600'>Área exclusiva</span>
              <p className='fs-12 lh-15 text-grayscale--400 mb-1'>
                Se você não for uma pessoa surda ou com deficiência auditiva, acesse a
                <a
                  href='https://ajuda.inter.co/'
                  className='fs-12 lh-15' title='Acessar Central de Ajuda'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: dataLayer.section,
                      section_name: dataLayer.section_name,
                      element_action: 'click button',
                      element_name: 'Central de Ajuda.',
                    })
                  }}
                > Central de Ajuda.
                </a>
              </p>
            </div>
          </div>
        </CentralDeAjuda>
      </div>
      {
        notDisabled &&
          <div>
            <h3 className='font-sora fs-32 lh-40 text-grayscale--500 fw-600 mb-4'>
              Atendimento exclusivo
            </h3>
            <p className='fs-16 lh-20 text-grayscale--400 mb-4'>
              Esse canal é exclusivo para pessoas surdas ou com deficiência auditiva. Favor acessar a nossa Central de Ajuda
            </p>
            <LinkButton
              href='https://ajuda.inter.co/'
              title='Acessar Central de Ajuda'
              className='btn bg-orange--extra text-white fs-14 text-none'
              onClick={() =>
                sendDatalayerEvent({
                  section: dataLayer.section,
                  section_name: dataLayer.section_name,
                  element_action: 'click button',
                  element_name: oneOption,
                })
              }
            >
              Acessar central de ajuda
            </LinkButton>
          </div>
        }
    </Container>
  )
}
